<template>
	<div class="cart__addon-wr">
		<h3 class="cart__addon-text">Добавьте к заказу</h3>
		<div class="swiper-wrapper">
			<div class="cart__addon-slider">
				<swiper
					:options="swiperOption"
					ref="mySwiper"
					@scroll.stop.prevent
				>
					<swiper-slide
						:key="meal.id"
						v-for="meal in meals"
					>
						<MealListItem
							:id="parseInt(meal.id)"
							:item="meal"
							:imgs="getImgs(meal.preview_picture, meal.detail_picture)"
							:type="meal.type"
							:nutrition="{
							calories: meal.kkal,
							proteins: meal.belki,
							fats: meal.jiri,
							carbs: meal.uglevodi
						}"
							:rating="5"
							:name="meal.name"
							:badges="[]"
							:composition="meal.sostav"
							:discount="parseInt(meal.discount)"
							:price="meal.price"
							:oldPrice="meal.price_old"
							:inAppPrice="null"
							:weight="parseInt(meal.weight)"
							:bonus="parseInt(meal.balls)"
							:imgBackground="getCategoryColor(meal?.type?.code)"
							@selected="$emit('selected', meal)"
						>
						</MealListItem>
					</swiper-slide>
				</swiper>
				<div class="cart-list-item-slider__arrows big">
					<div class="cart-list-item-slider__arrow-left big">
						<div
							class=" cart-list-item-slider__arrow-fix big"
							:class="'cart-list-item-slider__arrow-left-wrapper'"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="18"
								fill="none"
							>
								<path
									stroke="#3D4248"
									stroke-width="3"
									d="m9.5 1-7 8 7 7.8"
								/>
							</svg>
						</div>
					</div>
					<div class="cart-list-item-slider__arrow-right big">
						<div
							class="cart-list-item-slider__arrow-fix big"
							:class="'cart-list-item-slider__arrow-right-wrapper'"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="12"
								height="18"
								fill="none"
							>
								<path
									stroke="#3D4248"
									stroke-width="3"
									d="m9.5 1-7 8 7 7.8"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import 'swiper/css/swiper.css'
import Tooltip from '@/components/block/Tooltip.vue'
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import MealListItem from '@/components/block/Menu/MealListItem.vue'
import {mapGetters} from "vuex";

export default {
	components: {Swiper, SwiperSlide, Tooltip, MealListItem},
	data()
	{
		return {
			itemActive: true,
			swiperActiveIndex: 0,
			count: 0,
			activeSlideCat: '',
			virtualData: [],
		}
	},
	computed:
		{
			...mapGetters({
				meals: 'getMeals',
			}),
			/**
			 * вернуть swiper
			 */
			swiperOption()
			{
				return {
					slidesPerView: 1,
					freeMode: true,
					direction: 'horizontal',
					spaceBetween: 24,
					navigation:
						{
							nextEl: '.cart-list-item-slider__arrow-right',
							prevEl: '.cart-list-item-slider__arrow-left',
						},
					breakpoints:
						{
							1024: {slidesPerView: 3,},
							600: {slidesPerView: 2,}
						}
				}
			},
		},
	watch:
		{
			returnCurrenProduct:
				{
					/**
					 * обновляет значение количества текущего итема в корзине
					 * в соответствии с уже заданным количеством
					 */
					handler: function (newValue)
					{
						this.count = newValue ? newValue : 0;
					},
					deep: true
				},
		},
	methods:
		{
			getCategoryColor(category)
			{
				if (!category || !this.$store.state.menuCategories) return;

				const res = this.$store.state.menuCategories.find(c => c.type == category);

				if (!res || !res.color) return;

				return `#${res.color}`;
			},
			getImgs(preview, detail)
			{
				const imgs = [];

				if (preview?.thumb)
					imgs.push(preview.thumb)

				if (detail?.thumb)
					imgs.push(detail.thumb)

				return imgs;
			},
		}
}

</script>

<style lang="scss">
.meal__third-row-add
{
	cursor: pointer;
	padding: 8px 22px;
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #FFFFFF;
	width: 112px;
}

.meal__third-row
{
	display: flex;
	align-items: center;
	margin-top: 20px;
	justify-content: space-between;
}

.meal__third-row-bonus
{
	margin-left: 0;
	margin-right: 15px;
	width: 42px;
	height: 22px;
}

.meal-types
{
	display: flex;
	align-items: center;
}

.meal__second-row-name
{
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: #3D4248;
	max-width: 80%;
	max-height: 38px;
	min-height: 38px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
}

.meal-types__tooltip
{
	background-image: url('~@/assets/img/icons/info.svg');
	background-size: 18px;
	min-width: 18px;
	width: 18px;
	height: 18px;
	margin-left: 4px;
}

.meal__composition
{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	margin-top: 8px;
	max-height: 35px;
	min-height: 35px;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	margin-top: 4px;
	color: #979797;
	-webkit-line-clamp: 2;
}

.meal__second-row
{
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

.meal-types-sheet
{
	background-image: url('~@/assets/img/sheet.svg');
	background-size: 18px;
	height: 18px;
	width: 18px;
	margin-right: 5px;
}

.cart__addon-wr
{
	max-width: 898px;

	.swiper-slide
	{min-width: 284px;}

	&.hidden
	{display: none;}
}

.card__addon-arrows
{z-index: 3;}

.cart__addon-text
{
	font-weight: 700;
	font-size: 24px;
	line-height: 44px;
	letter-spacing: -0.5px;
	color: #3D4248;
	text-align: left;
	padding-bottom: 8px;
	border-bottom: 1px solid #E5E5E7;
	margin-bottom: 32px;
}

.cart__addon-slider
{
	min-width: 0;
	position: relative;
}

.cart-list-item-slider
{
	display: flex;
	justify-content: center;
	position: relative;

	&__slide
	{
		object-fit: contain;
		width: inherit;
		height: inherit;
		position: absolute;
	}

	&__trans
	{
		&-enter-active, &-leave-active
		{
			transition: opacity .3s;
		}

		&-enter, &-leave-to
		{
			opacity: 0;
		}
	}

	&__arrows
	{
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 0 12px;
		top: calc(50% - 16px);
		opacity: 1;
		transition: opacity .3s ease;
		pointer-events: none;
		z-index: 3;

		&.big
		{
			opacity: 1;
			top: calc(50% - 32px);
		}
	}

	&__arrow-left
	{left: -35px;}

	&__arrow-right
	{right: -35px;}

	&__arrow-left, &__arrow-right
	{
		pointer-events: all;
		cursor: pointer;
		width: 32px;
		height: 32px;
		border-radius: 100px;
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;

		&.big
		{
			width: 60px;
			height: 60px;
			position: absolute;
			box-shadow: 0px 10px 40px rgba(123, 123, 123, 0.15);
		}
	}

	&__arrow-fix
	{
		display: flex;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

	&__arrow-right
	{transform: scaleX(-1);}

	&__points
	{
		display: flex;
		position: absolute;
		bottom: 10px;
		z-index: 2;
	}

	&__point
	{
		width: 9px;
		height: 9px;
		border-radius: 100%;
		background: $greyDelimiter;
		border: 1px solid rgba(151, 151, 151, 0.7);
		margin-right: 8px;
		transition: all .3s ease;
		cursor: pointer;

		&.active
		{
			background: $green;
			border-color: $green;
		}
	}

	&:hover
	{
		.meal-list-item-slider__arrows
		{
			z-index: 2;
			opacity: 1;
		}
	}

	.swiper-slide
	{
		&__img
		{
			object-fit: contain;
			height: inherit;
			width: inherit;
		}
	}
}
</style>
